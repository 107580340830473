// Import the functions you need from the SDKs you need
import { initializeApp }    from "firebase/app";
import { getAnalytics }     from "firebase/analytics";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCYn3ZaCHpJNQ96tXiBpfexGrOIp-_4vFc",
    authDomain: "tune-trafikskole.firebaseapp.com",
    projectId: "tune-trafikskole",
    storageBucket: "tune-trafikskole.appspot.com",
    messagingSenderId: "459023601847",
    appId: "1:459023601847:web:b7e7bfb171213134e73d32",
    measurementId: "G-NX358F6PS6"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);